import InfoCard from "components/InfoCard";
import React, { useState } from "react";
import { IntlGetText } from "util/IntlMessages";
import PharmacotherapeuticRiskDialog from "./dialog";
import { useDispatch, useSelector } from "react-redux";
import {
  callApi,
  callApiAsync,
  getLastPharmacotherapeuticRiskLevel,
  getPharmacotherapeuticRisk
} from "api/apiList";
import { callStartImpact } from "actions/Impact";

const PharmacotherapeuticRisk = ({ prescription }) => {
  const [open, setOpen] = useState(false); 
   const dispatch = useDispatch();
  
  const [pharmacotherapeuticData, setPharmacotherapeuticData] = useState(null);
  const [riskLevel, seRiskLevel] = useState({});
  const { impactStartDate} = useSelector(
    ({ impact }) => impact
  );
  const { selectedPrescription } = useSelector(({ management }) => management);

  const handleRequestClose = () => setOpen(false);

  const patientData = prescription.patient;

  const loadPharmacotherapeutic = () => {
    callApiAsync(
      () => getPharmacotherapeuticRisk(selectedPrescription.patient.id),
      (data) => {
        setPharmacotherapeuticData(data);
      }
    );
  };

  const getLastRiskLevel = async (data) => {
    await callApiAsync(
      () => getLastPharmacotherapeuticRiskLevel(patientData.id),
      (data) => {
        seRiskLevel(data);
      }
    );
  };

  const handleClickOpen = () => {
    if (!impactStartDate) {
          dispatch(callStartImpact(true));
          return;
        }
    // if (isCIRequired && !consentSigned) { //notificacion condicional
    //   NotificationManager.warning(msgConsentRequired);
    //   return;
    // }
    getLastRiskLevel();
    setOpen(true);
  };

  return (
    <div>
      <InfoCard
        data={{
          icon: "assignment",
          title: IntlGetText("pharmacotherapeutic.title"),
          subTitle: "",
          color: "#757575"
        }}
        onClickBox={handleClickOpen}
      />

      <PharmacotherapeuticRiskDialog
        prescription={prescription}
        open={open}
        riskLevel={riskLevel}
        handleRequestClose={handleRequestClose}
        loadPharmacotherapeutic={loadPharmacotherapeutic}
        pharmacotherapeuticData={pharmacotherapeuticData}
        disableBackdropClick={true}
        setPharmacotherapeuticData={setPharmacotherapeuticData}
      />
    </div>
  );
};

export default PharmacotherapeuticRisk;
