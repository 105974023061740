import React, { useState, useEffect } from "react";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import TablePT from "./table";
import FormPT from "./form";
import {
  callApiAsync,
  getLastPharmacotherapeuticRiskLevel,
  postPharmacotherapeuticRisk
} from "api/apiList";
import { NotificationManager } from "react-notifications";

const PharmacotherapeuticRiskDialog = ({
  open,
  prescription,
  handleRequestClose,
  loadPharmacotherapeutic,
  pharmacotherapeuticData,
  setPharmacotherapeuticData,
  riskLevelData
}) => {
  const patientData = prescription.patient;
  const [openPT, setOpenPT] = useState(false);
  const [currentRowObj, setCurrentRowObj] = useState({});
  const [riskLevel, seRiskLevel] = useState({});

  const successNotification = IntlGetText("core.SuccessSave");

  useEffect(() => {
    if (open) loadPharmacotherapeutic();
  }, [open]);

  const handleOpenPT = () => setOpenPT(true);
  const handleRequestClosePT = () => setOpenPT(false);

  const handleCreateUpdate = async (data) => {
    await callApiAsync(
      () => postPharmacotherapeuticRisk(data),
      () => {
        NotificationManager.success(successNotification);
        loadPharmacotherapeutic();
        getLastRiskLevel();
        handleRequestClosePT();
      }
    );
  };
  const getLastRiskLevel = async (data) => {
    await callApiAsync(
      () => getLastPharmacotherapeuticRiskLevel(patientData.id),
      (data) => {
        seRiskLevel(data);
      }
    );
  };

  useEffect(() => {
    if(riskLevelData) {
      return;
    } 
    getLastRiskLevel();
  }, [pharmacotherapeuticData]);

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="pharmacotherapeutic.title" />

          <p>
            {patientData.name +
              " " +
              patientData.lastName +
              " - " +
              riskLevel?.name}
          </p>
        </DialogTitle>
        <DialogContent>
          <TablePT
            data={!pharmacotherapeuticData ? [] : pharmacotherapeuticData}
            handleOpenPT={handleOpenPT}
            setCurrentRowObj={setCurrentRowObj}
          />
          <FormPT
            rowObj={currentRowObj}
            open={openPT}
            handleCreateUpdate={handleCreateUpdate}
            handleRequestClose={handleRequestClosePT}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PharmacotherapeuticRiskDialog;
