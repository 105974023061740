import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import {
  callApi,
  callApiAsync,
  getAdverseReactionByPersonId,
  saveAdverseReaction,
} from "api/apiList";
import { callStartImpact } from "actions/Impact";
import TableAdverseReaction from "./TableAdverseReaction";
import FormAdverseReaction from "./formAdverseReaction";

const AdverseReaction = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { impactStartDate, consentSigned, isCIRequired} = useSelector(
    ({ impact }) => impact
  );
  const msgConsentRequired = IntlGetText("impact.consentRequired");

  const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [adverseReactionTableData, setAdverseReactionTableData] = useState([]);
  const [currentRowObj, setCurrentRowObj] = useState(null);

  const handleRequestClose = () => {
    setOpen(false);
  };

  const handleRequestCloseCRU = () => {
    setCurrentRowObj(null);
    setOpenForm(false);
  };

  const handleOpenCRU = () => {
    setOpenForm(true);
  };

  const loadTable = () =>
    callApi(
      () => getAdverseReactionByPersonId(selectedPrescription.patient.id),
      (data) => setAdverseReactionTableData(data)
    );

  useEffect(() => {
    loadTable();
  }, []);

  const handleClickOpen = () => {
    if (!impactStartDate) {
      dispatch(callStartImpact(true));
      return;
    }
    if (isCIRequired && !consentSigned) {
      NotificationManager.warning(msgConsentRequired);
      return;
    }
    setOpen(true);
  };

  const handleCreateUpdate = async (data) => {
    await callApiAsync(() => saveAdverseReaction(data));
    loadTable();
  };

  return (
    <div>
      <InfoCard
        data={{
          icon: "alert-octagon",
          title: IntlGetText("AdverseReaction.Title"),
          subTitle: "",
          color: "#757575",
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="AdverseReaction.Title" />
        </DialogTitle>
        <DialogContent>
          <TableAdverseReaction
            data={adverseReactionTableData}
            handleOpenCRU={handleOpenCRU}
            setCurrentRowObj={setCurrentRowObj}
            loadTable={loadTable}
          />
          <FormAdverseReaction
            openForm={openForm}
            handleRequestClose={handleRequestCloseCRU}
            rowObj2={currentRowObj}
            handleCreateUpdate={handleCreateUpdate}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdverseReaction;
