import http from "../services/httpService";
import { NotificationManager } from "react-notifications";

const urlApi = `${process.env.REACT_APP_API_URL}`;
const urlApiV2 = `${process.env.REACT_APP_API_URL}/v2`;
const urlApiJM = `${process.env.REACT_APP_API_URL}/jm`; //JaasModule

export const callActionApi = (apiFunc, setData, handleError, setLoader) => {
  const fetchData = async () => {
    try {
      await apiFunc()
        .then((response) => {
          if (response) {
            setData(response.data);
          }
        })
        .catch((err) => {
          var error = "";
          if (err.response) {
            if (err.response.data) {
              error = err.response.data.error;
              console.log("error-response: ", err.response.data.error);
            } else {
              error = err.response;
              console.log("error-response: ", err.response);
            }
          } else if (err.request) {
            error = err.request;
            console.log("error-request: ", err.request);
          } else {
            error = err;
            console.log("error-error: ", err);
          }

          if (handleError) {
            console.log("handleError: -> ", error);
            handleError(error);
          }
        })
        .then(() => {
          if (setLoader) setLoader(false);
        });
    } catch (e) {
      console.log("error: ", e.message);
    }
  };
  if (setLoader) setLoader(true);
  fetchData();
};

export const callActionApiAsync = async (
  apiFunc,
  setData,
  handleError,
  setLoader
) => {
  if (setLoader) setLoader(true);
  try {
    await apiFunc()
      .then(async (response) => {
        if (response) {
          if (setData.constructor.name === "AsyncFunction")
            await setData(response.data);
          else setData(response.data);
        }
      })
      .catch((err) => {
        var error = "";
        if (err.response) {
          if (err.response.data) {
            error = err.response.data.error;
            console.log("error-response: ", err.response.data.error);
          } else {
            error = err.response;
            console.log("error-response: ", err.response);
          }
        } else if (err.request) {
          error = err.request;
          console.log("error-request: ", err.request);
        } else {
          error = err;
          console.log("error-error: ", err);
        }

        if (handleError) {
          console.log("handleError: -> ", error);
          handleError(error);
        }
      })
      .then(() => {
        if (setLoader) setLoader(false);
      });
  } catch (e) {
    console.log("error: ", e.message);
  }
};

export const callApi = (apiFunc, setData, setLoader) => {
  callActionApi(
    apiFunc,
    setData,
    (error) => {
      if (typeof error === "string") NotificationManager.error(error);
    }, //, (data) => dispatch(setLoader(data))
    (data) => {
      if (setLoader) setLoader(data);
    }
  );
};

export const callApiAsync = async (apiFunc, setData, setLoader) => {
  await callActionApiAsync(
    apiFunc,
    setData,
    (error) => {
      if (typeof error === "string") NotificationManager.error(error);
    }, //, (data) => dispatch(setLoader(data))
    (data) => {
      if (setLoader) setLoader(data);
    }
  );
};

export const getAllCity = () => http.get(`${urlApi}/City`);
export const getCitiesByStateProvinceId = (stateProvinceId) =>
  http.get(`${urlApi}/City/${stateProvinceId}`);
export const getAllStateProvince = () => http.get(`${urlApi}/StateProvince`);
export const getAllDocumentType = () => http.get(`${urlApi}/DocumentType`);
export const getInsuranceList = () => http.get(`${urlApi}/Insurance`);
export const getInstitutionList = () => http.get(`${urlApi}/Institution`);
export const getDoctorList = () => http.get(`${urlApi}/Doctor`);
export const getProductList = () => http.get(`${urlApi}/Product`);
export const getDiseasesByProductId = (productId) =>
  http.get(`${urlApi}/Disease/${productId}`);
export const GetCommentsByType = (type) =>
  http.get(`${urlApi}/Comment/${type}`);
export const getEducationalMaterial = (prescripctionId) =>
  http.get(`${urlApi}/EducationalMaterial/${prescripctionId}`);
export const getMarketChainList = () => http.get(`${urlApi}/MarketChain`);
export const getImpactTypeList = () => http.get(`${urlApi}/ImpactType`);
export const getDosageUnitList = () => http.get(`${urlApi}/DosageUnit`);
export const getAdverseEventTypeList = () =>
  http.get(`${urlApi}/AdverseEventType`);
export const getAllCountry = () => http.get(`${urlApi}/Country`);
export const getAllCausality = () => http.get(`${urlApi}/Causality`);

export const postChangePassword = (data) =>
  http.post(`${urlApi}/UserManagement/changepassword`, data);

export const getInfoConsent = (prescriptionId) =>
  http.get(`${urlApi}/Consent/${prescriptionId}`);
export const postConsentSaveSign = (data) =>
  http.post(`${urlApi}/Consent`, data);
export const postConsentAddExisting = (data) =>
  http.post(`${urlApi}/Consent/AddExisting`, data);
export const postConsentAddAudio = (data) =>
  http.post(`${urlApi}/Consent/AddAudio`, data);

export const getPharmacyByMarketChain = (marketchainId) =>
  http.get(
    `${urlApi}/Pharmacy/GetByMarketchain?marketchainId=${marketchainId}`
  );
export const postAddPharmacy = (data) => http.post(`${urlApi}/Pharmacy/`, data);

export const postNewPatientAlert = (data) =>
  http.post(`${urlApi}/Alert/newPatient`, data);
export const postGenerateAlert = (data) =>
  http.post(`${urlApi}/Alert/GenerateAlert`, data);
export const getAlertList = (prescriptionId) =>
  http.get(`${urlApi}/Alert/${prescriptionId}`);
export const getCommentsByAlert = (alertId) =>
  http.get(`${urlApi}/Alert/GetCommentsByAlert?alertId=${alertId}`);
export const postAddCommentToAlert = (data) =>
  http.post(`${urlApi}/Alert/AddCommentToAlert`, data);

//export const getAdministrationRouteList = () => http.get(`${urlApi}/AdministrationRoute`);
//export const getAttentionScopeList = () => http.get(`${urlApi}/AttentionScope`);

export const getPatientList = () => http.get(`${urlApi}/Patient`);
//export const getPatientListByNurseId = (nurseId) => http.get(`${urlApi}/Patient/GetByNurseId/?nurseId=${nurseId}`);
export const getActivePatientsIndicator = () =>
  http.get(`${urlApi}/Patient/ActivePatientsIndicator`);
export const getPatientInfo = (prescriptionId) =>
  http.get(`${urlApi}/Patient/${prescriptionId}`);
export const getGetProcessDetailPatient = (prescriptionId) =>
  http.get(
    `${urlApi}/Patient/GetProcessDetail?prescriptionId=${prescriptionId}`
  );
export const postPatientSave = (data) => http.post(`${urlApi}/Patient`, data);
export const postPatientSaveHousing = (data) =>
  http.post(`${urlApi}/Patient/SaveHousing`, data);
export const postPatientSaveTutor = (data) =>
  http.post(`${urlApi}/Patient/SaveTutor`, data);
export const postActivatePatient = (data) =>
  http.post(`${urlApi}/Patient/ActivatePatient`, data);
export const postActivatePatientByPrescriptionId = (data) =>
  http.post(`${urlApi}/Patient/ActiveByPrescriptionId`, data);
export const postActivatePatientFromDropOut = (data) =>
  http.post(`${urlApi}/Patient/ActiveFromDropOut`, data);
export const postSuspendedOrDeceased = () =>
  http.get(`${urlApi}/Patient/GetSuspendedOrDeceasedState`);
export const postSuspendedOrDeceasedFile = () =>
  http.get(`${urlApi}/Patient/GetSuspendedOrDeceasedStateFile`);
export const getPatientListManagement = () =>
  http.get(`${urlApi}/Patient/GetPatientListManagement`);

export const getAllExternalReports = () => http.get(`${urlApi}/ExternalReport`);
export const getExternalReportById = (id) =>
  http.get(`${urlApi}/ExternalReport/GetOne?id=${id}`);
export const getExternalReportsByDate = (startDate, endDate) =>
  http.get(
    `${urlApi}/ExternalReport/GetByDate?startDate=${startDate}&&endDate=${endDate}`
  );
export const putExternalReport = (data) =>
  http.post(`${urlApi}/ExternalReport`, data);
export const patchExternalReport = (data) =>
  http.patch(`${urlApi}/ExternalReport`, data);
export const deleteExternalReport = (id) =>
  http.delete(`${urlApi}/ExternalReport/${id}`);

export const getProductsAndStates = () =>
  http.get(`${urlApi}/Prescription/GetProductsAndStates`);
export const getPrescriptions = (type) =>
  http.get(`${urlApi}/Prescription/${type}`);
export const getGetFormula = (impactId) =>
  http.get(`${urlApi}/Prescription/GetFormula?impactId=${impactId}`);
export const getFormulaReadOnly = (prescriptionId) =>
  http.get(
    `${urlApi}/Prescription/GetFormulaReadOnly?prescriptionId=${prescriptionId}`
  );

export const getGetHealthData = (impactId) =>
  http.get(`${urlApi}/Prescription/GetHealthData?impactId=${impactId}`);
export const getGetHealthDataByPrescriptionId = (prescriptionId) =>
  http.get(
    `${urlApi}/Prescription/GetHealthDataByPrescriptionId?prescriptionId=${prescriptionId}`
  );

export const getPrescriptionsForPlanning = () =>
  http.get(`${urlApi}/Prescription/GetPrescriptionsForPlanning`);
export const getPrescriptionByPatient = (patientId) =>
  http.get(`${urlApi}/Prescription/GetByPatient?patientId=${patientId}`);
export const postDoctorAuthorization = (data) =>
  http.post(`${urlApi}/Prescription/DoctorAuthorization`, data);
export const postAddContactDetails = (data) =>
  http.post(`${urlApi}/Prescription/AddContactDetails`, data);
export const postPatientAuthorization = (data) =>
  http.post(`${urlApi}/Prescription/PatientAuthorization`, data);
export const postSaveFormula = (data) =>
  http.post(`${urlApi}/Prescription/SaveFormula`, data);
export const postSaveHealthData = (data) =>
  http.post(`${urlApi}/Prescription/SaveHealthData`, data);
export const postFormulaFile = (data) =>
  http.post(`${urlApi}/Prescription/SaveFormulaFile`, data);
export const postApproveFormula = (data) =>
  http.post(`${urlApi}/Prescription/ApproveFormula`, data);
export const postCancelFormula = (data) =>
  http.post(`${urlApi}/Prescription/CancelFormula`, data);
export const postPrescriptionSetState = (data) =>
  http.post(`${urlApi}/Prescription/SetState`, data);
export const postPrescriptionNewFormulation = (data) =>
  http.post(`${urlApi}/Prescription/NewFormulation`, data);
export const postAddNewPrescription = (data) =>
  http.post(`${urlApi}/Prescription/AddNewPrescription`, data);
export const postSetDropoutState = (data) =>
  http.post(`${urlApi}/Prescription/SetDropoutState`, data);
export const getExpirationReport = (type, startDate, endDate) =>
  http.get(
    `${urlApi}/Prescription/GetExpirationReport?type=${type}&&startDate=${startDate}&&endDate=${endDate}`
  );

export const getInfoImpact = (id) => http.get(`${urlApi}/Impact?Id=${id}`);
export const getPlannedImpactsByNurseId = (nurseId) =>
  http.get(`${urlApi}/Impact/GetPlannedImpacts?nurseId=${nurseId}`);
export const getPlannedImpacts = () =>
  http.get(`${urlApi}/Impact/GetPlannedImpacts`);
export const getImpactsByPrescription = (prescriptionId) =>
  http.get(
    `${urlApi}/Impact/GetByPrescription?prescriptionId=${prescriptionId}`
  );
export const postPlanningFirstImpact = (data) =>
  http.post(`${urlApi}/Impact/PlanningFirstImpact`, data);
export const postImpactStartImpact = (data) =>
  http.post(`${urlApi}/Impact/StartImpact`, data);
export const postImpactEndImpact = (data) =>
  http.post(`${urlApi}/Impact/EndImpact`, data);
export const postRescheduleImpact = (data) =>
  http.post(`${urlApi}/Impact/RescheduleImpact`, data);
//export const postImpactAddFile = (data) => http.post(`${urlApi}/Impact/AddFile`, data);
export const postPlanImpact = (data) =>
  http.post(`${urlApi}/Impact/PlanImpact`, data);
export const getImpactReport = (startDate, endDate) =>
  http.get(
    `${urlApi}/Impact/GetReport?startDate=${startDate}&&endDate=${endDate}`
  );
export const getImpactReportFile = (startDate, endDate) =>
  http.get(
    `${urlApi}/Impact/GetReportFile?startDate=${startDate}&&endDate=${endDate}`
  );

export const getNursingPlans = (prescriptionId) =>
  http.get(`${urlApi}/NursingPlan?prescriptionId=${prescriptionId}`);
export const postCreateUpdateNursingPlan = (data) =>
  http.post(`${urlApi}/NursingPlan`, data);

export const getAdditionalMedications = (prescriptionId) =>
  http.get(`${urlApi}/AdditionalMedication/${prescriptionId}`);
export const postCreateUpdateAdditionalMedication = (data) =>
  http.post(`${urlApi}/AdditionalMedication`, data);
export const deleteAdditionalMedication = (additionalMedicationId) =>
  http.delete(`${urlApi}/AdditionalMedication/${additionalMedicationId}`);
export const getMedication = () =>
  http.get(`${urlApi}/AdditionalMedication/GetMedicaments`);
export const getDiagnosticByProduct = (productId) =>
  http.get(
    `${urlApi}/AdditionalMedication/GetDiagnosticByproduct/${productId}`
  );

export const getDosageV2 = (prescriptionId) =>
  http.get(`${urlApi}/DosageV2/${prescriptionId}`);
export const postCreateUpdateDosageV2 = (data) =>
  http.post(`${urlApi}/DosageV2`, data);
export const getUnitListTime = () => http.get(`${urlApi}/DosageV2/GetUnitList`);
export const getUnitDurationListTime = () =>
  http.get(`${urlApi}/DosageV2/GetUnitDurationList`);
export const deleteDosage = (data) => http.delete(`${urlApi}/DosageV2/${data}`);

export const getClinicHistoryList = (impactId) =>
  http.get(`${urlApi}/ClinicHistory/${impactId}`);
export const getClinicHistoryDetail = (impactId, variableGroupId, folderType) =>
  http.get(
    `${urlApi}/ClinicHistory?impactId=${impactId}&variableGroupId=${variableGroupId}&folderType=${folderType}`
  );
export const getCHPatientDetail = (patientId) =>
  http.get(`${urlApi}/ClinicHistory/CHPatientDetail?patientId=${patientId}`);
export const postClinicHistory = (data) =>
  http.post(`${urlApi}/ClinicHistory`, data);
export const postClinicHistoryFile = (data) =>
  http.post(`${urlApi}/ClinicHistory/file`, data);
export const getReportBCRHema = (startDate, endDate) =>
  http.get(
    `${urlApi}/ClinicHistory/GetReportBCRHema?startDate=${startDate}&&endDate=${endDate}`
  );
export const getReportPendingRSVariables = () =>
  http.get(
    `${urlApi}/ClinicHistory/GetReportPendingRiskStratificationVariables`
  );

export const getRadicadoInsuranceList = (prescriptionId) =>
  http.get(`${urlApi}/RadicadoInsurance/${prescriptionId}`);
export const postCreateUpdateRadicadoInsurance = (data) =>
  http.post(`${urlApi}/RadicadoInsurance`, data);
export const deleteRadicadoInsurance = (id) =>
  http.delete(`${urlApi}/RadicadoInsurance/${id}`);

export const getInsuranceAuthorization = (prescriptionId) =>
  http.get(`${urlApi}/InsuranceAuthorization/${prescriptionId}`);
export const postCreateUpdateInsuranceAuthorization = (data) =>
  http.post(`${urlApi}/InsuranceAuthorization`, data);
export const deleteInsuranceAuthorization = (id) =>
  http.delete(`${urlApi}/InsuranceAuthorization/${id}`);
export const postAddFileInsuranceAuthorization = (data) =>
  http.post(`${urlApi}/InsuranceAuthorization/AddFile`, data);

export const getRadicadoOLFList = (prescriptionId) =>
  http.get(`${urlApi}/RadicadoOLF/${prescriptionId}`);
export const postCreateUpdateRadicadoOLF = (data) =>
  http.post(`${urlApi}/RadicadoOLF`, data);
export const deleteRadicadoOLF = (id) =>
  http.delete(`${urlApi}/RadicadoOLF/${id}`);
export const postAddFileRadicadoOLF = (data) =>
  http.post(`${urlApi}/RadicadoOLF/AddFile`, data);

export const getMedicineDeliveryList = (prescriptionId) =>
  http.get(`${urlApi}/MedicineDelivery/${prescriptionId}`);
export const postCreateUpdateMedicineDelivery = (data) =>
  http.post(`${urlApi}/MedicineDelivery`, data);
export const deleteMedicineDelivery = (id) =>
  http.delete(`${urlApi}/MedicineDelivery/${id}`);
export const postAddFileMedicineDelivery = (data) =>
  http.post(`${urlApi}/MedicineDelivery/AddFile`, data);

export const getDosageInfo = (prescriptionId) =>
  http.get(`${urlApi}/Dosage/${prescriptionId}`);
export const postCreateUpdateDosage = (data) =>
  http.post(`${urlApi}/Dosage`, data);

export const getClinicalFinding = (prescriptionId) =>
  http.get(`${urlApi}/ClinicalFinding/${prescriptionId}`);
export const getCommentsByClinicalFinding = (clinicalFindingId) =>
  http.get(
    `${urlApi}/ClinicalFinding/GetCommentsByClinicalFinding?clinicalFindingId=${clinicalFindingId}`
  );
export const postAddClinicalFinding = (data) =>
  http.post(`${urlApi}/ClinicalFinding`, data);
export const postAddCommentToClinicalFinding = (data) =>
  http.post(`${urlApi}/ClinicalFinding/AddComment`, data);
export const postGenerateAdverseEvent = (data) =>
  http.post(`${urlApi}/ClinicalFinding/GenerateAdverseEvent`, data);

export const getRemodulinPumpList = (prescriptionId) =>
  http.get(`${urlApi}/RemodulinPump/${prescriptionId}`);
export const postCreateUpdateRemodulinPump = (data) =>
  http.post(`${urlApi}/RemodulinPump`, data);
export const deleteRemodulinPump = (id) =>
  http.delete(`${urlApi}/RemodulinPump/${id}`);
export const getRemodulinPumpReport = (startDate, endDate) =>
  http.get(
    `${urlApi}/RemodulinPump/GetReport?startDate=${startDate}&&endDate=${endDate}`
  );
export const getRemodulinPumpReportFile = (startDate, endDate) =>
  http.get(
    `${urlApi}/RemodulinPump/GetReportFile?startDate=${startDate}&&endDate=${endDate}`
  );

export const getRemodulinTrackingList = (prescriptionId) =>
  http.get(`${urlApi}/RemodulinTracking/${prescriptionId}`);
export const postCreateUpdateRemodulinTracking = (data) =>
  http.post(`${urlApi}/RemodulinTracking`, data);
export const deleteRemodulinTracking = (id) =>
  http.delete(`${urlApi}/RemodulinTracking/${id}`);

export const getMoriskySurveyList = (prescriptionId) =>
  http.get(`${urlApi}/MoriskySurvey/${prescriptionId}`);
export const postCreateUpdateMoriskySurvey = (data) =>
  http.post(`${urlApi}/MoriskySurvey`, data);
export const deleteMoriskySurvey = (id) =>
  http.delete(`${urlApi}/MoriskySurvey/${id}`);
export const getMoriskyExtended = (startDate, endDate) =>
  http.get(
    `${urlApi}/MoriskySurvey/GetExtended?startDate=${startDate}&&endDate=${endDate}`
  );
export const getMoriskyExtendedReport = (startDate, endDate) =>
  http.get(
    `${urlApi}/MoriskySurvey/GetReportFile?startDate=${startDate}&&endDate=${endDate}`
  );

export const getEducationLevelSurveyList = (prescriptionId) =>
  http.get(`${urlApi}/EducationLevelSurvey/${prescriptionId}`);
export const postCreateUpdateEducationLevelSurvey = (data) =>
  http.post(`${urlApi}/EducationLevelSurvey`, data);
export const deleteEducationLevelSurvey = (id) =>
  http.delete(`${urlApi}/EducationLevelSurvey/${id}`);

export const getImpactFileList = (data) =>
  http.post(`${urlApi}/ImpactFile/ImpactFileList`, data);
export const postAddImpactFile = (data) =>
  http.post(`${urlApi}/ImpactFile`, data);
export const deleteImpactFile = (id) =>
  http.delete(`${urlApi}/ImpactFile/${id}`);

export const getMedicineConsumptionList = (prescriptionId) =>
  http.get(`${urlApi}/MedicineConsumption/${prescriptionId}`);
export const getMedicineList = (impactId) =>
  http.get(
    `${urlApi}/MedicineConsumption/GetMedicineList?impactId=${impactId}`
  );
export const getGetIndicators = (prescriptionId) =>
  http.get(
    `${urlApi}/MedicineConsumption/GetIndicators?prescriptionId=${prescriptionId}`
  );
export const postCreateUpdateMedicineConsumption = (data) =>
  http.post(`${urlApi}/MedicineConsumption`, data);
export const deleteMedicineConsumption = (id) =>
  http.delete(`${urlApi}/MedicineConsumption/${id}`);

export const getSupplyAditionalMedicationsList = (prescriptionId) =>
  http.get(`${urlApi}/SupplyAditionalMedications/${prescriptionId}`);
export const getSupplyTypeList = () =>
  http.get(`${urlApi}/SupplyAditionalMedications/GetSupplyTypeList`);
export const postCreateUpdateSupplyAditionalMedications = (data) =>
  http.post(`${urlApi}/SupplyAditionalMedications`, data);
export const deleteSupplyAditionalMedications = (id) =>
  http.delete(`${urlApi}/SupplyAditionalMedications/${id}`);
export const getConsolidatedSupply = (startDate, endDate) =>
  http.get(
    `${urlApi}/SupplyAditionalMedications/GetConsolidatedSupplyReport?startDate=${startDate}&endDate=${endDate}`
  );

export const getProductStatusList = (prescriptionId) =>
  http.get(`${urlApi}/ProductStatus/${prescriptionId}`);
export const postCreateUpdateProductStatus = (data) =>
  http.post(`${urlApi}/ProductStatus`, data);
export const deleteProductStatus = (id) =>
  http.delete(`${urlApi}/ProductStatus/${id}`);

export const getAdverseEventList = () => http.get(`${urlApi}/AdverseEvent`);
export const getAdverseEventByClinicalFinding = (clinicalFindingId) =>
  http.get(
    `${urlApi}/AdverseEvent/GetAdverseEvent?clinicalFindingId=${clinicalFindingId}`
  );
export const getAdverseEventGetDetail = (adverseEventId) =>
  http.get(`${urlApi}/AdverseEvent/GetDetail?adverseEventId=${adverseEventId}`);
export const getEventsFollowUp = (adverseEventId) =>
  http.get(
    `${urlApi}/AdverseEvent/GetEventsFollowUp?adverseEventId=${adverseEventId}`
  );
export const getProcessDataByAdverseEvent = (adverseEventId) =>
  http.get(
    `${urlApi}/AdverseEvent/GetProcessData?adverseEventId=${adverseEventId}`
  );
export const getKnownAdverseEvent = (adverseEventId) =>
  http.get(
    `${urlApi}/AdverseEvent/GetKnownAdverseEvent?adverseEventId=${adverseEventId}`
  );
export const postAdverseEventPartialSave = (data) =>
  http.post(`${urlApi}/AdverseEvent`, data);
export const postAdverseEventSave = (data) =>
  http.post(`${urlApi}/AdverseEvent/Save`, data);
export const postAdverseEventUpdateState = (data) =>
  http.post(`${urlApi}/AdverseEvent/UpdateState`, data);
export const postAdverseEventReject = (data) =>
  http.post(`${urlApi}/AdverseEvent/Reject`, data);
export const postAdverseEventGenerateFormat = (data) =>
  http.post(`${urlApi}/AdverseEvent/GenerateFormat`, data);
export const postAdverseEventRegisterCausality = (data) =>
  http.post(`${urlApi}/AdverseEvent/RegisterCausality`, data);
export const postAdverseEventCreateFollowUp = (data) =>
  http.post(`${urlApi}/AdverseEvent/CreateFollowUp`, data);
export const postAdverseEventCreateFollowUpQuery = (data) =>
  http.post(`${urlApi}/AdverseEvent/CreateFollowUpQuery`, data);
export const postAddKnownAdverseEvent = (data) =>
  http.post(`${urlApi}/AdverseEvent/AddKnownAdverseEvent`, data);
export const postAdverseEventNullify = (data) =>
  http.post(`${urlApi}/AdverseEvent/Nullify`, data);

export const getConcomitantMedicationList = (adverseEventId) =>
  http.get(
    `${urlApi}/ConcomitantMedication/GetAll?adverseEventId=${adverseEventId}`
  );
export const postConcomitantMedicationSave = (data) =>
  http.post(`${urlApi}/ConcomitantMedication`, data);

export const getQueryAdverseEventList = (adverseEventId) =>
  http.get(`${urlApi}/QueryAdverseEvent/${adverseEventId}`);
export const postCreateUpdateQueryAdverseEvent = (data) =>
  http.post(`${urlApi}/QueryAdverseEvent`, data);
export const getCommentsByQuery = (queryId) =>
  http.get(`${urlApi}/QueryAdverseEvent/GetCommentsByQuery?queryId=${queryId}`);
export const postCreateUpdateCommentToQuery = (data) =>
  http.post(`${urlApi}/QueryAdverseEvent/CreateUpdateCommentToQuery`, data);

export const getUserByRole = (role) =>
  http.get(`${urlApi}/User/GetUserByRole?role=${role}`);
export const getUserByRoles = (data) =>
  http.post(`${urlApi}/User/GetUserListByRoles`, data);

export const getEventTrainingDetail = (id) =>
  http.get(`${urlApi}/EventTraining/${id}`);
export const postAddUpdateEventTraining = (data) =>
  http.post(`${urlApi}/EventTraining`, data);
export const postAddUpdateFeedback = (data) =>
  http.post(`${urlApi}/EventTraining/AddUpdateFeedback`, data);
export const postAddUpdateResource = (data) =>
  http.post(`${urlApi}/EventTraining/AddUpdateResource`, data);
export const deleteEventTrainingResource = (id) =>
  http.delete(`${urlApi}/EventTraining/DeleteResource?id=${id}`);
export const getEventTrainingReport = (startDate, endDate) =>
  http.get(
    `${urlApi}/EventTraining/GetReport?startDate=${startDate}&&endDate=${endDate}`
  );

export const getNursesByPrescription = (prescriptionId) =>
  http.get(
    `${urlApi}/Nurse/GetByPrescription?prescriptionId=${prescriptionId}`
  );
export const postAddNurseToPrescription = (data) =>
  http.post(`${urlApi}/Nurse`, data);
export const deleteNurseToPrescription = (id) =>
  http.delete(`${urlApi}/Nurse/${id}`);

export const getControlPanelReportRepKam = (productBehavior) =>
  http.get(`${urlApi}/ControlPanelRepKam/GetReport?product=${productBehavior}`);
export const getControlPanelReportFileRepKam = (productBehavior) =>
  http.get(
    `${urlApi}/ControlPanelRepKam/GetReportFile?product=${productBehavior}`
  );
export const postDashboardNurse = (data) =>
  http.post(`${urlApi}/Dashboard`, data);
export const getPatientDistributionByEpsAndState = (data) =>
  http.post(`${urlApi}/Dashboard/GetPatientDistributionByEpsAndState`, data);
export const getPatientByIpsAndRegion = (data) =>
  http.post(`${urlApi}/Dashboard/GetPatientByIpsAndRegion`, data);

//export const getDoctorDetail = (doctorId,minDate,maxDate) => http.get(`${urlApi}/Dashboard/GetDoctorDetail?doctorId=${doctorId}&&minDate=${minDate}&&maxDate=${maxDate}`);
export const getDoctorDetail = (data) =>
  http.post(`${urlApi}/Dashboard/GetDoctorDetail`, data);

export const getReloginInfo = () => http.get(`${urlApi}/Relogin`);

//Reports
export const getControlPanelReport = (productBehavior) => http.get(`${urlApi}/ControlPanel/GetReport?product=${productBehavior}`);
export const getControlPanelReportFile = (productBehavior) => http.get(`${urlApi}/ControlPanel/GetReportFile?product=${productBehavior}`);
export const getClinicHistoryReportFile = (startDate, endDate) => http.get(`${urlApi}/ClinicHistory/GetReportFile?startDate=${startDate}&&endDate=${endDate}`);
export const getSuveyMoriskyReportFile = (startDate, endDate) => http.get(`${urlApi}/MoriskySurvey/GetSurveyMoriskyReportFile?startDate=${startDate}&&endDate=${endDate}`);
export const getConsumptionReportFile = (startDate, endDate) => http.get(`${urlApi}/MedicineConsumption/GetReportFile?startDate=${startDate}&&endDate=${endDate}`);
export const getCatheterChangeReportFile = (startDate, endDate) => http.get(`${urlApi}/RemodulinTracking/GetReportFile?startDate=${startDate}&&endDate=${endDate}`);
export const getCubeReportFile = (startDate, endDate) => http.get(`${urlApi}/ControlPanel/GetCubeReportFile?startDate=${startDate}&&endDate=${endDate}`);
export const getConsolidatedSupplyReport = (startDate, endDate) => http.get(`${urlApi}/SupplyAditionalMedications/GetReportFile?startDate=${startDate}&endDate=${endDate}`);
export const getDoseChangeReportFile = (startDate, endDate) => http.get(`${urlApi}/Dosage/GetDoseChangeReportFile?startDate=${startDate}&&endDate=${endDate}`);
export const GetPatientsTotalFile = (startDate, endDate) => http.get(`${urlApi}/Prescription/GetPatientsTotalFile?startDate=${startDate}&&endDate=${endDate}`);
export const postControlPanelDeliveries = (data) => http.post(`${urlApi}/Report/GetDeliveries`, data);
export const postRemodulinVialsReport = (data) => http.post(`${urlApi}/Report/GetRemodulinVials`, data);
export const getGeneralJaasielReportFile = (startDate, endDate) => http.get(`${urlApi}/Report/GetGeneralJaasiel?startDate=${startDate}&&endDate=${endDate}`);
export const getBehringerReportFile = (startDate, endDate) => http.get(`${urlApi}/Report/GetBehringer?startDate=${startDate}&&endDate=${endDate}`);
export const getCelltrionReportFile = (startDate, endDate) => http.get(`${urlApi}/Report/GetCelltrion?startDate=${startDate}&endDate=${endDate}`);

//PatientContext
export const getPatientPAList = (data) =>
  http.post(`${urlApi}/PatientPA/GetAll`, data);
export const getPatientPAReportList = (data) =>
  http.post(`${urlApi}/PatientPA/GetPatientReport`, data);
export const getPatientPAInfo = (patientId) =>
  http.get(`${urlApi}/PatientPA/GetPatientInfo?id=${patientId}`);
export const getPatientEdit = (id) =>
  http.get(`${urlApi}/PatientPA/GetPatientEdit?id=${id}`);
export const postAddPatientPA = (data) =>
  http.post(`${urlApi}/PatientPA`, data);
export const patchActivatePatient = (id) =>
  http.patch(`${urlApi}/PatientPA`, { id });
export const patchUpdateExternalId = (data) =>
  http.patch(`${urlApi}/PatientPA/UpdateExternalId`, data);
export const getImpactPAList = (id) => http.get(`${urlApi}/ImpactPA/${id}`);
export const postAddUpdateImpactPA = (data) =>
  http.post(`${urlApi}/ImpactPA`, data);
export const getPatientPAListToSync = () =>
  http.get(`${urlApi}/PatientPA/GetListToSync`);
export const getPatientCommentList = (patientId) =>
  http.get(`${urlApi}/PatientCommentPA/${patientId}`);
export const postAddPatientComment = (data) =>
  http.post(`${urlApi}/PatientCommentPA`, data);
export const getRepList = () => http.get(`${urlApi}/RepPA`);
export const patchUpdateRep = (data) => http.patch(`${urlApi}/RepPA`, data);
export const getDashboardPA = (data) =>
  http.post(`${urlApi}/DashboardPA`, data);
export const getDashboardOperative = (data) =>
  http.post(`${urlApi}/DashboardOperativePA`, data);

export const getDashboardClinical = (data) =>
  http.post(`${urlApi}/DashboardClinical`, data);
export const getCyclesQuery = (data) =>
  http.post(`${urlApi}/DashboardClinical/GetCyclesQuery`, data);
export const getInstitutionsList = () =>
  http.get(`${urlApi}/DashboardClinical/GetInstitutionsList`);
export const getDoctorPAList = (data) =>
  http.post(`${urlApi}/DoctorPA/GetAll`, data);
export const getInsurancePAList = () => http.get(`${urlApi}/InsurancePA`);

//End patientContext

//V2
export const getPrescriptionsV2 = () => http.get(`${urlApiV2}/Prescription`);
export const postPrescriptionsList = (data) =>
  http.post(`${urlApiV2}/Prescription/GetAll`, data);
export const getPrescriptionInfo = (id) =>
  http.get(`${urlApiV2}/Prescription/${id}`);
export const postPrescriptionAdd = (data) =>
  http.post(`${urlApiV2}/Prescription`, data);
export const postPrescriptionChangeState = (data) =>
  http.post(`${urlApiV2}/Prescription/ChangeState`, data);
export const getPrescriptionStatusList = (id) =>
  http.get(`${urlApiV2}/Prescription/GetPrescriptionStatus`);
export const postPatientAdd = (data) => http.post(`${urlApiV2}/Patient`, data);
export const getPatientInfoV2 = (id) => http.get(`${urlApiV2}/Patient/${id}`);
export const getImpactsByPrescriptionV2 = (prescriptionId) =>
  http.get(`${urlApiV2}/Impact/${prescriptionId}`);
export const getImpactInfo = (id) =>
  http.get(`${urlApiV2}/Impact/GetDetail?id=${id}`); //Este es, si es mas de un atributo se usa POST mejor
export const postImpactAdd = (data) => http.post(`${urlApiV2}/Impact`, data);
export const postStartImpact = (data) =>
  http.post(`${urlApiV2}/Impact/StartImpact`, data);
export const postScheduleImpact = (data) =>
  http.post(`${urlApiV2}/Impact/Schedule`, data);
export const postEndImpact = (data) =>
  http.post(`${urlApiV2}/Impact/EndImpact`, data);
export const postCoordinatorComment = (data) =>
  http.post(`${urlApiV2}/Impact/CoordinatorComment`, data);
export const getMedicineDeliveryListV2 = (prescriptionId) =>
  http.get(`${urlApiV2}/Delivery/${prescriptionId}`);
export const postDeliveryAdd = (data) =>
  http.post(`${urlApiV2}/Delivery`, data);
export const getPrescriptionStateLog = (id) =>
  http.get(`${urlApiV2}/Prescription/GetStateLog?id=${id}`);
export const getDashboardFilters = () => http.get(`${urlApiV2}/Dashboard`);
export const postDashboard = (data) => http.post(`${urlApiV2}/Dashboard`, data);
export const postDashboardMigration = (data) =>
  http.post(`${urlApiV2}/DashboardMigration`, data);
export const dashboardGetEficaciaRecoveries = (data) =>
  http.post(`${urlApiV2}/DashboardMigration/GetEficaciaRecoveries`, data);
export const dashboardGetTotal = (data) =>
  http.post(`${urlApiV2}/DashboardMigration/GetTotal`, data);
export const dashboardGetByCausal = (data) =>
  http.post(`${urlApiV2}/DashboardMigration/GetByCausal`, data);
export const dashboardGetByOperator = (data) =>
  http.post(`${urlApiV2}/DashboardMigration/GetByOperator`, data);
export const dashboardGetByProductLine = (data) =>
  http.post(`${urlApiV2}/DashboardMigration/GetByProductLine`, data);
export const dashboardGetTotalMIPRES = (data) =>
  http.post(`${urlApiV2}/DashboardMigration/GetTotalMIPRES`, data);
export const dashboardGetImpactsInfo = (data) =>
  http.post(`${urlApiV2}/DashboardMigration/GetImpactsInfo`, data);
export const getMedicineConsumptionTracingList = (prescriptionId) =>
  http.get(`${urlApiV2}/MedicineConsumptionTracing/${prescriptionId}`);
export const postCreateUpdateConsumptionTracing = (data) =>
  http.post(`${urlApiV2}/MedicineConsumptionTracing`, data);
export const deleteConsumptionTracing = (id) =>
  http.delete(`${urlApiV2}/MedicineConsumptionTracing/${id}`);
export const getGetIndicatorsTracing = (prescriptionId) =>
  http.get(
    `${urlApiV2}/MedicineConsumptionTracing/GetIndicators?prescriptionId=${prescriptionId}`
  );

export const getDashboardMigrationFilters = () =>
  http.get(`${urlApiV2}/DashboardMigration`);
export const getPrescriptionReportFile = (startDate, endDate, isFile) =>
  http.get(
    `${urlApiV2}/Report/GetPrescriptionsFile?startDate=${startDate}&&endDate=${endDate}&&isFile=${isFile}`
  );
export const getDeliveriesReportFile = (startDate, endDate, isFile) =>
  http.get(
    `${urlApiV2}/Report/GetDeliveriesFile?startDate=${startDate}&&endDate=${endDate}&&isFile=${isFile}`
  );
export const getImpactsReportFile = (startDate, endDate, isFile) =>
  http.get(
    `${urlApiV2}/Report/GetImpactsFile?startDate=${startDate}&&endDate=${endDate}&&isFile=${isFile}`
  );
export const GetPrescriptionsTotalReportFile = (startDate, endDate, isFile) =>
  http.get(
    `${urlApiV2}/Report/GetPrescriptionsTotalFile?startDate=${startDate}&&endDate=${endDate}&&isFile=${isFile}`
  );

//End V2

//Institution Context
export const getInstitutionImpactInfo = (id) =>
  http.get(`${urlApi}/InstitutionImpact/${id}`);
export const getInstitutions = () =>
  http.get(`${urlApi}/InstitutionImpact/GetInstitutions`);
export const getInstitutionInfo = () =>
  http.get(`${urlApi}/InstitutionImpact/GetInstitutionInfo`);
export const postAddIntitutionImpact = (data) =>
  http.post(`${urlApi}/InstitutionImpact`, data);
export const getIntitutionImpacts = (data) =>
  http.post(`${urlApi}/InstitutionImpact/GetAll`, data);
export const postIntitutionStartImpact = (data) =>
  http.post(`${urlApi}/InstitutionImpact/StartImpact`, data);
export const postIntitutionEndImpact = (data) =>
  http.post(`${urlApi}/InstitutionImpact/EndImpact`, data);
export const postIntitutionSaveProcess = (data) =>
  http.post(`${urlApi}/InstitutionImpact/SaveProcess`, data);
export const postIntitutionActivatePatient = (data) =>
  http.post(`${urlApi}/PatientI/ActivatePatient`, data);
export const postSignByCompany = (data) =>
  http.post(`${urlApi}/InstitutionImpact/SignByCompany`, data);
export const getNewPatientList = () => http.get(`${urlApi}/PatientI`);
export const getInstitutionPatientInfo = (id) =>
  http.get(`${urlApi}/PatientI/GetPatientInfo?id=${id}`);
export const getInstitutionImpactFiles = (id) =>
  http.get(`${urlApi}/InstitutionImpactFile/${id}`);
export const postAddInstitutionImpactFile = (data) =>
  http.post(`${urlApi}/InstitutionImpactFile`, data);
export const deleteInstitutionImpactFile = (fileId) =>
  http.delete(`${urlApi}/InstitutionImpactFile/${fileId}`);
export const getWoundClinicReportFile = (startDate, endDate) =>
  http.get(
    `${urlApi}/InstitutionReport/GetWoundClinicReport?startDate=${startDate}&&endDate=${endDate}`
  );
//End Institution Context

//JaasModule
export const getPatientStateLog = (personId) =>
  http.get(`${urlApiJM}/Patient/GetPatientStateList?personId=${personId}`);
export const getPatientStateList = () =>
  http.get(`${urlApiJM}/Patient/GetPatientStates`);
export const postPatientAssignState = (data) =>
  http.post(`${urlApiJM}/Patient/AssignState`, data);
export const postUpdateConfig = (data) =>
  http.post(`${urlApiJM}/Patient/UpdateConfig`, data);
export const getGetCities = () =>
  http.get(`${urlApiJM}/TracingDelivery/GetCities`);
export const getGetCompanies = () =>
  http.get(`${urlApiJM}/TracingDelivery/GetCompanies`);
export const getMarketChainListTD = () =>
  http.get(`${urlApiJM}/TracingDelivery/GetMarketChainList`);
export const getPharmaAmount = (prescriptionId) =>
  http.get(
    `${urlApiJM}/TracingDelivery/GetPharmaceuticalAmount?prescriptionId=${prescriptionId}`
  );
export const getTracingDeliverList = (prescriptionId) =>
  http.get(
    `${urlApiJM}/TracingDelivery/GetTracingDeliveryList?prescriptionId=${prescriptionId}`
  );
export const postCreateUpdateTracingDelivery = (data) =>
  http.post(`${urlApiJM}/TracingDelivery/CreateUpdate`, data);

export const getBarrierList = (impactId) =>
  http.get(`${urlApiJM}/Patient/GetBarriers?impactId=${impactId}`);
export const postUpdateBarrier = (data) =>
  http.post(`${urlApiJM}/Patient/UpdateBarrier`, data);

//End JaasModule

//DiagnosisModule
export const getDiagnosisSelects = () =>
  http.get(`${urlApi}/Disease/GetDiagnosisSelects`);
export const getDiagnosisRecordByPersonId = (personId) =>
  http.get(
    `${urlApi}/Disease/GetDiagnosisRecordByPersonId?PersonId=${personId}`
  );
export const SaveDiagnosisRecord = (data) =>
  http.post(`${urlApi}/Disease/SaveDiagnosisRecord`, data);
export const saveFamilyHistoryIds = (data) =>
  http.post(`${urlApi}/Disease/SaveFamilyHistoryIds`, data);
export const saveDiagnosisRecordConfirmationTests = (data) =>
  http.post(`${urlApi}/Disease/SaveDiagnosisRecordConfirmationTests`, data);
export const DeleteDiagnosticRecordConfirmationTestCommand = (id) =>
  http.delete(`${urlApi}/Disease/DeleteDiagnosticRecordConfirmationTest/${id}`);
export const DeleteFamilyHistoryCommand = (id) =>
  http.delete(`${urlApi}/Disease/DeleteFamilyHistory/${id}`);
export const postDiagnosisFile = (data) =>
  http.post(`${urlApi}/Disease/file`, data);
export const GetDiagnosisRecordConfirmationTestByDiagnosisRecordId = (
  diagnosisRecordId
) =>
  http.get(
    `${urlApi}/Disease/GetDiagnosisRecordConfirmationTestByDiagnosisRecordId?diagnosisRecordId=${diagnosisRecordId}`
  );

//End DiagnosisModule

export const getAdverseReactionByPersonId = (personId) => http.get(`${urlApi}/AdverseReaction/GetAdverseReactionByPersonId?PersonId=${personId}`);
export const saveAdverseReaction = (data)  => http.post(`${urlApi}/AdverseReaction/SaveAdverseReaction`, data);
export const postAdverseReactionFile = (data) => http.post(`${urlApi}/AdverseReaction/file`, data);
export const getAdverseReactionTypes = () => http.get(`${urlApi}/AdverseReaction/GetAdverseReactionTypes`);

//ControlExamModule
export const getControlExamSelects = () =>
  http.get(`${urlApi}/ControlExam/GetControlExamSelects`);
export const getControlExamTable = (personId) =>
  http.get(
    `${urlApi}/ControlExam/GetControlExamByPersonId?personId=${personId}`
  );
export const saveControlExamRecord = (data) =>
  http.post(`${urlApi}/ControlExam/SaveControlExamRecord`, data);
export const postControlExamFile = (data) =>
  http.post(`${urlApi}/ControlExam/file`, data);

//End ControlExamModule

export const getTimeUnitSelect = () =>
  http.get(`${urlApi}/AdditionalMedication/GetTimeUnitSelect`);
export const postTracingDeliveryFile = (data) =>
  http.post(`${urlApiJM}/TracingDelivery/file`, data);

export const getProductlineByProductId = (productId) =>
  http.get(
    `${urlApi}/AdditionalMedication/GetProductlineByProductId/${productId}`
  );

//PharmacotherapeuticRisk
export const getPharmacotherapeuticRisk = (personId) =>
  http.get(`${urlApi}/pharmacotherapeuticRiskLevel/${personId}`);
export const postPharmacotherapeuticRisk = (data) =>
  http.post(
    `${urlApi}/pharmacotherapeuticRiskLevel/createUpdatePharmacotherapeuticRiskLevel`,
    data
  );
export const getLastPharmacotherapeuticRiskLevel = (personId) =>
  http.get(
    `${urlApi}/pharmacotherapeuticRiskLevel/getLastPharmacotherapeuticRiskLevel?personId=${personId}`
  );
