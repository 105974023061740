import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Grid, Button } from "@material-ui/core";
import FormDatePicker from "components/Form/Controls/datepicker";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import ImpactReport from "./impacts";
import ControlPanelReport from "./controlPanel";
import RemodulinPumps from "./remodulinPumps";
import DownloadReportFile from "./downloadReportFile";
import BCRHemogramaReport from "./bcrHemograma";
import ExpirationReport from "./expirationReport";
//import ExpirationReport from "./patientReport";
import MoriskyInfo from "./moriskyInfo";
import PendingRSVariables from "./pendingRSVariables";
import SuspendedPatients from "./suspendedPatients";
import ExternalReports from "./externalReport"
import FeedbackEventTraining from "./feedbackEventTraining"
import { getUserInfo, isJaasModule, isRole, getBoolSetting } from "app/routes/psp/services/userService";
import {
  ProductBehavior,
  ReportListPint,
  ReportListGeneric,
  ReportEnum,
  ExpirationReportTypeEnum,
  ShardKeyEnum,
  RoleEnum,
  SettingsEnum
} from "constants/Enums";
import {
  getClinicHistoryReportFile,
  getSuveyMoriskyReportFile,
  getConsumptionReportFile,
  getCatheterChangeReportFile,
  getCubeReportFile,
  getConsolidatedSupplyReport,
  getDoseChangeReportFile,
  GetPatientsTotalFile,
  postControlPanelDeliveries,
  postRemodulinVialsReport,
  getWoundClinicReportFile,
  getGeneralJaasielReportFile,
  getBehringerReportFile,
  getCelltrionReportFile
} from "api/apiList";

const Reports = () => {
  const [dateRange, setDateRange] = useState(null);
  const [maxEndDate, setMaxEndDate] = useState(undefined);
  const [minEndDate, setMinEndDate] = useState(undefined);
  const [disabledEndDate, setDisabledEndDate] = useState(false);
  const msgEndDate =
    IntlGetText("clinicalFindingState.endDateInvalid") +
    IntlGetText("core.startDate");

  const defaultValues = {
    startDate: null
  };

  const validationSchema = {
    startDate: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />)
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { errors, trigger, getValues, watch , setValue} = methods;

  const reportValue = watch("report");
  const startDateValue = watch("startDate");

  const showReport = (reportId) =>
    reportValue && reportValue.value === reportId;

  const showDateRange = (reportId) => {
    if (!reportValue) return true;
    var reportId = reportValue.value;
    return (
      reportId !== ReportEnum.controlPanelRemodulin &&
      reportId !== ReportEnum.controlPanelIclusig &&
      reportId !== ReportEnum.controlPanelGalafold &&
      reportId !== ReportEnum.pendingRSVariables &&
      reportId !== ReportEnum.suspendedPatients &&
      reportId !== ReportEnum.externalReports &&
      reportId !== ReportEnum.deliveries 
    );
  };
  const isCelltrion = getUserInfo().shardKey === ShardKeyEnum.celltrion;
  const isBehringer = getUserInfo().shardKey === ShardKeyEnum.atulado;
  const isUllpukuy = getUserInfo().shardKey === ShardKeyEnum.ullkupuy;
  const isJaasielModule = isJaasModule();
  const isReportMorisky = getBoolSetting(SettingsEnum.reportMorisky);

  const getReportList = () => {
    if(isBehringer){
      const filteredReports = ReportListGeneric().filter(x=>x.id != ReportEnum.woundClinic && x.id != ReportEnum.generalJaasiel);
      filteredReports.push({ id: 32, name: IntlGetText("report.behringer") });
      return filteredReports;
    }
    if(isCelltrion){
      const filteredReports = ReportListGeneric().filter(x=>x.id != ReportEnum.woundClinic && x.id != ReportEnum.generalJaasiel);
      filteredReports.push({ id: 33, name: IntlGetText("report.celltrion") });
      return filteredReports;
    }
    if(getUserInfo().shardKey === ShardKeyEnum.pintPharma){
      return ReportListPint();
    }
    if(isUllpukuy){
      const isPatientAssignment = isRole(RoleEnum.patient_assignment);
      const isCoordinator = isRole(RoleEnum.psp_coordinator);
      const isManager = isRole(RoleEnum.psp_manager);
      if(isPatientAssignment && !isManager && !isCoordinator)
        return ReportListGeneric().filter(x=>x.id == ReportEnum.impacts);
      return ReportListGeneric().filter(x=>x.id != ReportEnum.generalJaasiel && x.id);
    }
    if(isJaasielModule)
    {
      if(isReportMorisky){
        const filteredReports = ReportListGeneric().filter(x=>x.id != ReportEnum.woundClinic);
      filteredReports.push({ id: 26, name: IntlGetText("reports.surveyMorisky") });
      return filteredReports;
      }
      return ReportListGeneric().filter(x=>x.id != ReportEnum.woundClinic);
    }
      
    if(isReportMorisky){
      const filteredReports = ReportListGeneric().filter(x => x.id != ReportEnum.woundClinic && x.id != ReportEnum.generalJaasiel);
      filteredReports.push({ id: 26, name: IntlGetText("reports.surveyMorisky") });
      return filteredReports;
    }
    return ReportListGeneric().filter(x=>x.id != ReportEnum.woundClinic && x.id != ReportEnum.generalJaasiel);
  }

  useEffect(() => {
    if(reportValue && reportValue.value == ReportEnum.behringerReport){
      setDisabledEndDate(true);
      if(startDateValue != null){
        const year = dayjs(startDateValue).year(); 
        const month = dayjs(startDateValue).month(); 
        const lastDayOfMonth = dayjs(startDateValue).endOf('month').date();
        setMinEndDate(dayjs(`${year}-${month + 1}-01`));
        setMaxEndDate(dayjs(`${year}-${month + 1}-${lastDayOfMonth}`));
        setValue("endDate",dayjs(`${year}-${month + 1}-${lastDayOfMonth}`));
      }
    }else{
      setDisabledEndDate(false);
      setMinEndDate(undefined);
      setMaxEndDate(undefined);
    }
  }, [reportValue, startDateValue])

  return (
    <>
      <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-5">
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} lg={3}>
              <FormSelectAutoComplete
                name="report"
                label={<IntlMessages id="core.report" />}
                options={getReportList()}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            {showDateRange() && (
              <>
                <Grid item xs={12} md={3} lg={3}>
                  <FormDatePicker
                    name="startDate"
                    label={<IntlMessages id="core.startDate" />}
                    format="YYYY-MM-DD"
                    margin="normal"
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <FormDatePicker
                    name="endDate"
                    label={<IntlMessages id="core.endDate" />}
                    format="YYYY-MM-DD"
                    margin="normal"
                    errorobj={errors}
                    minDate = {minEndDate}
                    maxDate = {maxEndDate}
                    disabled = {disabledEndDate}
                  />
                </Grid>

                <Grid item xs={12} md={3} lg={3}>
                  <Button
                    onClick={async () => {
                      if (!(await trigger())) return;
                      var range = getValues();
                      var rangeFormated = {
                        startDate: dayjs(range.startDate).format(
                          "YYYY-MM-DDT00:00:00"
                        ),
                        endDate: !range.endDate ? 
                          dayjs().format("YYYY-MM-DDT00:00:00"): 
                          dayjs(range.endDate).format("YYYY-MM-DDT00:00:00"),
                      };
                      setDateRange(rangeFormated);
                    }}
                    className="mt-3"
                    variant="contained"
                    color="primary"
                  >
                    <IntlMessages id="core.search" />
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </FormProvider>
      </div>
      {showReport(ReportEnum.impacts) && <ImpactReport dateRange={dateRange} />}
      {showReport(ReportEnum.controlPanelRemodulin) && (
        <ControlPanelReport productBehavior={ProductBehavior.remodulin} />
      )}
      {showReport(ReportEnum.controlPanelIclusig) && (
        <ControlPanelReport productBehavior={ProductBehavior.iclusig} />
      )}
      {showReport(ReportEnum.controlPanelGalafold) && (
        <ControlPanelReport productBehavior={ProductBehavior.galafold} />
      )}
      {showReport(ReportEnum.clinicHistory) && (
        <DownloadReportFile
          dateRange={dateRange}
          actionApi={getClinicHistoryReportFile}
        />
      )}
      {showReport(ReportEnum.surveyMorisky) && (
        <DownloadReportFile
          dateRange={dateRange}
          actionApi={getSuveyMoriskyReportFile}
        />
      )}
      {showReport(ReportEnum.patientsReport) && (
        <DownloadReportFile
          dateRange={dateRange}
          actionApi={GetPatientsTotalFile}
        />
      )}
      {showReport(ReportEnum.medicineConsumption) && (
        <DownloadReportFile
          dateRange={dateRange}
          actionApi={getConsumptionReportFile}
        />
      )}
      {showReport(ReportEnum.remodulinPumps) && (
        <RemodulinPumps dateRange={dateRange} />
      )}
      {showReport(ReportEnum.catherChange) && (
        <DownloadReportFile
          dateRange={dateRange}
          actionApi={getCatheterChangeReportFile}
        />
      )}
      {showReport(ReportEnum.bcrHemograma) && (
        <BCRHemogramaReport dateRange={dateRange} />
      )}
      {showReport(ReportEnum.pspCube) && (
        <DownloadReportFile
          dateRange={dateRange}
          actionApi={getCubeReportFile}
        />
      )}
      {showReport(ReportEnum.formulaExpiration) && (
        <ExpirationReport
          dateRange={dateRange}
          type={ExpirationReportTypeEnum.formula}
        />
      )}
      {showReport(ReportEnum.authorizationExpiration) && (
        <ExpirationReport
          dateRange={dateRange}
          type={ExpirationReportTypeEnum.authorization}
        />
      )}
      {showReport(ReportEnum.deliveryExpiration) && (
        <ExpirationReport
          dateRange={dateRange}
          type={ExpirationReportTypeEnum.delivery}
        />
      )}
      {showReport(ReportEnum.supplyReport) && (
        <DownloadReportFile
          dateRange={dateRange}
          actionApi={getConsolidatedSupplyReport}
        />
      )}
      {showReport(ReportEnum.morisky) && <MoriskyInfo dateRange={dateRange} />}
      {showReport(ReportEnum.pendingRSVariables) && <PendingRSVariables />}
      {showReport(ReportEnum.doseChange) && (
        <DownloadReportFile
          dateRange={dateRange}
          actionApi={getDoseChangeReportFile}
        />
      )}
      {showReport(ReportEnum.suspendedPatients) && <SuspendedPatients />}
      {showReport(ReportEnum.externalReports) && <ExternalReports />}
      {showReport(ReportEnum.feedbackEventTraining) && (
        <FeedbackEventTraining dateRange={dateRange} />
      )}
      {showReport(ReportEnum.deliveries) && (
        <DownloadReportFile
          dateRange={{}}
          actionApi={()=>postControlPanelDeliveries({})}
        />
      )} 
      {showReport(ReportEnum.remodulinVials) && (
        <DownloadReportFile
          dateRange={dateRange}
          actionApi={()=>postRemodulinVialsReport(dateRange)}
        />
      )} 
      {showReport(ReportEnum.woundClinic) && (
        <DownloadReportFile
          dateRange={dateRange}
          actionApi={getWoundClinicReportFile}
        />
      )}
      {showReport(ReportEnum.generalJaasiel) && (
        <DownloadReportFile
          dateRange={dateRange}
          actionApi={getGeneralJaasielReportFile}
        />
      )}
       {showReport(ReportEnum.behringerReport) && (
        <DownloadReportFile
          dateRange={dateRange}
          actionApi={getBehringerReportFile}
        />
      )}
      {showReport(ReportEnum.celltrionReport) && (
        <DownloadReportFile
          dateRange={dateRange}
          actionApi={getCelltrionReportFile}
        />
      )}
    </>
  );
};

export default Reports;
