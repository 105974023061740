import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid
} from "@material-ui/core";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import FormRadioGroupYesNo from "components/Form/Controls/RadioGroupYesNo";
import FormInput from "components/Form/Controls/input";
import { useSelector } from "react-redux";

const FormPT = ({ rowObj, open, handleRequestClose, handleCreateUpdate }) => {
  const { selectedPrescription } = useSelector(({ management }) => management);
  const onlyRead = rowObj?.state === 0;

  const defaultValues = {
    administrativeLimitations: null,
    socialDeterminants: null,
    healthcareAccessLimitations: null,
    inadequateMedicationUse: null,
    pharmaTreatmentFollowUp: null,
    therapeuticAdherence: null,
    multiPathology: "",
    patieRenalHepaticAlternt: null,
    polypharmacy: "",
    adverseReaction: null,
    treatmentIneffectiveness: null,
    hospitalReadmissions: null,
    urgentConsults: null,
    disability: null
  };

  const validationSchema = yup.object().shape({
    administrativeLimitations: yup
      .mixed()
      .test(
        "is-boolean-or-null",
        "Este campo es obligatorio.",
        (value) => value === true || value === false
      ),
    socialDeterminants: yup
      .mixed()
      .test(
        "is-boolean-or-null",
        "Este campo es obligatorio.",
        (value) => value === true || value === false
      ),
    healthcareAccessLimitations: yup
      .mixed()
      .test(
        "is-boolean-or-null",
        "Este campo es obligatorio.",
        (value) => value === true || value === false
      ),
    inadequateMedicationUse: yup
      .mixed()
      .test(
        "is-boolean-or-null",
        "Este campo es obligatorio.",
        (value) => value === true || value === false
      ),
    pharmaTreatmentFollowUp: yup
      .mixed()
      .test(
        "is-boolean-or-null",
        "Este campo es obligatorio.",
        (value) => value === true || value === false
      ),
    therapeuticAdherence: yup
      .mixed()
      .test(
        "is-boolean-or-null",
        "Este campo es obligatorio.",
        (value) => value === true || value === false
      ),
    multiPathology: yup
      .string()
      .nullable()
      .matches(/^\d+(\.\d+)?$/, "Debe ser un número válido.")
      .test("is-valid-range", "Debe estar entre 1 y 1000.", (value) => {
        if (!value) return true; // Permitir vacío
        const number = parseFloat(value);
        return number > 0 && number <= 1000;
      })
      .required("Este campo es obligatorio."),
    patieRenalHepaticAlternt: yup
      .mixed()
      .test(
        "is-boolean-or-null",
        "Este campo es obligatorio.",
        (value) => value === true || value === false
      ),
    polypharmacy: yup
      .string()
      .nullable()
      .matches(/^\d+(\.\d+)?$/, "Debe ser un número válido.")
      .test("is-valid-range", "Debe estar entre 1 y 1000.", (value) => {
        if (!value) return true; // Permitir vacío
        const number = parseFloat(value);
        return number > 0 && number <= 1000;
      })
      .required("Este campo es obligatorio."),
    adverseReaction: yup
      .mixed()
      .test(
        "is-boolean-or-null",
        "Este campo es obligatorio.",
        (value) => value === true || value === false
      ),
    treatmentIneffectiveness: yup
      .mixed()
      .test(
        "is-boolean-or-null",
        "Este campo es obligatorio.",
        (value) => value === true || value === false
      ),
    hospitalReadmissions: yup
      .mixed()
      .test(
        "is-boolean-or-null",
        "Este campo es obligatorio.",
        (value) => value === true || value === false
      ),
    urgentConsults: yup
      .mixed()
      .test(
        "is-boolean-or-null",
        "Este campo es obligatorio.",
        (value) => value === true || value === false
      ),
    disability: yup
      .mixed()
      .test(
        "is-boolean-or-null",
        "Este campo es obligatorio.",
        (value) => value === true || value === false
      )
  });

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema)
  });

  const { handleSubmit, setValue, watch, errors } = methods;

  const loadData = (rowObj) => {
    setValue(
      "inadequateMedicationUse",
      rowObj?.inadequateMedicationUse ?? null
    );
    setValue(
      "healthcareAccessLimitations",
      rowObj?.healthcareAccessLimitations ?? null
    );
    setValue("disability", rowObj?.disability ?? null);
    setValue(
      "administrativeLimitations",
      rowObj?.administrativeLimitations ?? null
    );
    setValue("adverseReaction", rowObj?.adverseReaction ?? null);
    setValue("hospitalReadmissions", rowObj?.hospitalReadmissions ?? null);
    setValue("multiPathology", rowObj?.multiPathology ?? "");
    setValue(
      "patieRenalHepaticAlternt",
      rowObj?.patieRenalHepaticAlternt ?? null
    );
    setValue(
      "pharmaTreatmentFollowUp",
      rowObj?.pharmaTreatmentFollowUp ?? null
    );
    setValue("socialDeterminants", rowObj?.socialDeterminants ?? null);
    setValue("therapeuticAdherence", rowObj?.therapeuticAdherence ?? null);
    setValue(
      "treatmentIneffectiveness",
      rowObj?.treatmentIneffectiveness ?? null
    );
    setValue("urgentConsults", rowObj?.urgentConsults ?? null);
    setValue("polypharmacy", rowObj?.polypharmacy ?? "");
  };

  const onSubmit = (data) => {
    const dataToSend = {
      id: rowObj ? rowObj.id : 0,
      patientId: selectedPrescription.patient.id,
      inadequateMedicationUse: data.inadequateMedicationUse,
      healthcareAccessLimitations: data.healthcareAccessLimitations,
      disability: data.disability,
      administrativeLimitations: data.administrativeLimitations,
      adverseReaction: data.adverseReaction,
      hospitalReadmissions: data.hospitalReadmissions,
      multiPathology: Number(data.multiPathology),
      patieRenalHepaticAlternt: data.patieRenalHepaticAlternt,
      pharmaTreatmentFollowU: data.pharmaTreatmentFollowUp,
      polypharmacy: Number(data.polypharmacy),
      socialDeterminants: data.socialDeterminants,
      therapeuticAdherence: data.therapeuticAdherence,
      treatmentIneffectiveness: data.treatmentIneffectiveness,
      urgentConsults: data.urgentConsults
    };
     handleCreateUpdate(dataToSend);
     handleRequestClose();
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => loadData(rowObj), 150);
    }
  }, [open]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id={!rowObj ? "core.add" : "core.edit"} />
      </DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <form className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <FormRadioGroupYesNo
                    name="administrativeLimitations" //Limitaciones Administrativas:
                    label="1. ¿Ha enfrentado dificultades administrativas para acceder a servicios de salud? (Ejemplo: trámites complicados, demoras en autorizaciones, etc.)"
                    defaultValue={null}
                    disabled={onlyRead}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormRadioGroupYesNo
                    name="socialDeterminants" //Determinantes Sociales
                    label="2. ¿Considera que su situación económica afecta su acceso a una atención médica adecuada?"
                    defaultValue={null}
                    disabled={onlyRead}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormRadioGroupYesNo
                    name="healthcareAccessLimitations" //Limitaciones de Acceso al Sistema de Salud:
                    label="3. ¿Ha tenido problemas para conseguir una cita médica en un tiempo razonable?"
                    disabled={onlyRead}
                    defaultValue={null}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormRadioGroupYesNo
                    name="inadequateMedicationUse" //Utilización Inadecuada de los Medicamentos:
                    label="4. ¿Ha consumido medicamentos de forma inadecuada? (Ejemplo: sin receta médica, medicamentos vencidos, dosis incorrectas, etc.)"
                    defaultValue={null}
                    disabled={onlyRead}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormRadioGroupYesNo
                    name="pharmaTreatmentFollowUp" // No Seguimiento del Tratamiento no Farmacológico ?
                    label="5. ¿Ha dejado de seguir recomendaciones no farmacológicas sugeridas por su médico? (Ejemplo: cambios en la dieta o ejercicio físico.)"
                    defaultValue={null}
                    disabled={onlyRead}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormRadioGroupYesNo
                    name="therapeuticAdherence" //No Adherencia Terapéutica
                    label="6. ¿Ha dejado de seguir recomendaciones médicas debido a efectos secundarios de un tratamiento? (Ejemplo: malestar asociado a medicamentos o terapias.)"
                    defaultValue={null}
                    disabled={onlyRead}
                  />
                </Grid>
                {/* //-------------------------------------------------------- */}
                <Grid item xs={12} md={6} lg={6}>
                  <FormLabel component="legend">
                    7. ¿Cuántas enfermedades crónicas o condiciones de salud le
                    han diagnosticado? (Ejemplo: hipertensión, diabetes,
                    obesidad, etc.)
                  </FormLabel>
                  <FormInput
                    name="multiPathology"
                    label=""
                    margin="normal"
                    errorobj={errors}
                    disabled={onlyRead}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormRadioGroupYesNo
                    name="patieRenalHepaticAlternt" //Alteraciones Renales/Hepáticas
                    label="8. ¿Le han diagnosticado alguna enfermedad renal crónica o alteración en la función hepática?"
                    defaultValue={null}
                    disabled={onlyRead}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormLabel component="legend">
                    9. ¿Cuántos medicamentos está tomando actualmente de forma
                    regular?
                  </FormLabel>
                  <FormInput
                    name="polypharmacy"
                    label=""
                    margin="normal"
                    errorobj={errors}
                    disabled={onlyRead}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormRadioGroupYesNo
                    name="adverseReaction" //Reacciones Adversas
                    label="10. ¿Ha experimentado reacciones adversas tras el uso de algún medicamento?"
                    defaultValue={null}
                    disabled={onlyRead}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormRadioGroupYesNo
                    name="treatmentIneffectiveness" //Inefectividad del Tratamiento
                    label="11. ¿El tratamiento logró los resultados esperados?"
                    defaultValue={null}
                    disabled={onlyRead}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormRadioGroupYesNo
                    name="hospitalReadmissions" //Reingresos Hospitalarios
                    label="12. ¿Ha sido reingresado al hospital por complicaciones relacionadas con su condición de salud?"
                    defaultValue={null}
                    disabled={onlyRead}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormRadioGroupYesNo
                    name="urgentConsults" //Consultas de Urgencias
                    label="13. ¿Ha tenido alguna consulta de Urgencias?"
                    defaultValue={null}
                    disabled={onlyRead}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormRadioGroupYesNo
                    name="disability" //Discapacidad
                    label="14. ¿Sufre alguna discapacidad?"
                    defaultValue={null}
                    disabled={onlyRead}
                  />
                </Grid>
              </Grid>
              <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
                <ButtonAsync
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  color="primary"
                >
                  {<IntlMessages id="core.save" />}
                </ButtonAsync>
              </div>
            </div>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleRequestClose()} color="secondary">
          <IntlMessages id="core.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormPT;
