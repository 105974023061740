import React from "react";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import Table, {
  getTextLabels,
} from "components/Form/Table";
import CardBox from "components/CardBox/index";

const TablePT = ({ data, handleOpenPT, setCurrentRowObj }) => {
  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    textLabels: getTextLabels()
  };

  const handleClick = (obj) => {
    setCurrentRowObj(!obj ? null : obj);
    handleOpenPT();
  };

  const getRowObj = (tableMeta) => tableMeta.tableData[tableMeta.rowIndex];

  const columns = [
    {
      name: "creationDate",
      label: IntlGetText("core.date"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        }
      }
    },
    {
      name: "pharmacotherapeuticRiskLevelText",
      label: IntlGetText("pharmacotherapeutic.risk"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var obj = getRowObj(tableMeta);
          return obj.pharmacotherapeuticRiskLevelText;
        }
      }
    },
    {
      name: "pharmacotherapeuticRiskLevelDescreiption",
      label: IntlGetText("core.detail"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var obj = getRowObj(tableMeta);
          return obj.pharmacotherapeuticRiskLevelDescreiption;
        }
      }
    },
    {
      name: "actions",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = getRowObj(tableMeta);
          if (rowObj.state === 0) {
            return (
              <div>
                <Button
                  onClick={() => handleClick(rowObj)}
                  variant="contained"
                  className="jr-btn jr-btn-xs"
                >
                  <i className="zmdi zmdi-eye"></i>
                </Button>
              </div>
            );
          }

          return (
            <div>
              <Button
                onClick={() => handleClick(rowObj)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className="zmdi zmdi-edit"></i>
              </Button>
            </div>
          );
        }
      }
    }
  ];

  return (
    <div className="row">
      <CardBox styleName="col-12" cardStyle="p-0 border-dialog-card">
        <Table
          className="border-dialog-card"
          data={data}
          columns={columns}
          options={options}
        />
      </CardBox>

      <div className="col-lg-12 col-md-12 col-xs-12 mt-4 text-center">
        <Button
          onClick={() => handleClick(null)}
          variant="contained"
          color="primary"
        >
          {<IntlMessages id="core.add" />}
        </Button>
      </div>
    </div>
  );
};

export default TablePT;
