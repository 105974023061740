import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormHelperText
} from "@material-ui/core";

function FormRadioGroupYesNo(props) {
  const { control, errors } = useFormContext();
  const { name, label, defaultValue, ...radioProps } = props;

  const errorMessage = errors?.[name]?.message || "";

  return (
    <div>
      <FormLabel component="legend">{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || null}
        render={({ onChange, value }) => ( 
          <>
            <RadioGroup
              row
              value={value}
              onChange={(e) => {
                const selectedValue = e.target.value === "true" ? true : false;
                onChange(selectedValue);
                if (props.onChange) props.onChange(selectedValue);
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio color="primary" {...radioProps} />}
                label="Sí"
              />
              <FormControlLabel
                value={false}
                control={<Radio color="primary" {...radioProps} />}
                label="No"
              />
            </RadioGroup>
            {errorMessage && (
              <FormHelperText error>{errorMessage}</FormHelperText>
            )}
          </>
        )}
      />
    </div>
  );
}

export default FormRadioGroupYesNo;
